// Here you can add other styles

.c-sidebar.c-sidebar-fixed {
  z-index: 1;
}

.c-header.c-header-fixed {
  z-index: 1000 !important;
}

/* 
* editable cell
*/
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #ebedef;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

[customclasses="custom-bootstrap-sidebar-nav-icon"] {
  width: 20px;
  height: 18px;
  margin-right: 16px;
  margin-left: 3px;
}

.child-table-expandable {
  margin: 0 10px 10px;
}

/* 
* for screens
*/
.active-screen-wrapper {
  display: flex;
}
.active-screen-wrapper svg {
  font-size: 26px;
  margin-left: 15px;
}

//tab pane
.canvas-tab .ant-tabs-tab {
  border: 0 !important;
}

//canvas
.canvas-container {
  overflow: auto;
  max-height: 1024px;
  max-width: 980px;
}

/*
* statitic content
*/
.ant-statistic-content {
  text-align: center;
}

/*
* media query
*/

@media (max-width: 1200px) {
  .ant-drawer-content-wrapper {
    width: 80% !important;
  }
}

@media (max-width: 991px) {
  .ant-drawer-content-wrapper,
  .ant-modal {
    width: 90% !important;
  }
}

@media screen and (max-width: 767px) {
  //table
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .drawer-wrapper .ant-drawer-content-wrapper {
    width: 90% !important;
  }

  a.c-header-nav-link.nav-link > span > h5 {
    display: none;
  }

  .ant-table-header {
    table {
      table-layout: auto !important;
      overflow-x: auto !important;
    }
  }

  .ant-table {
    table {
      width: auto !important;
    }
  }

  .ant-drawer-content-wrapper,
  .ant-modal {
    width: 95% !important;
  }

  .ant-card {
    width: 100% !important;
  }

  .ant-radio-group-outline {
    margin-left: 0 !important;
  }

  .pdf-download {
    left: 18em;
  }
}

@media only screen and (max-width: 992px) {
  .c-sidebar-backdrop {
    z-index: 0;
  }

  .c-sidebar.c-sidebar-dark.c-sidebar-show.c-sidebar-fixed {
    position: static !important;
  }
}

@media (max-width: 640px) {
  .ant-drawer-content-wrapper,
  .ant-modal {
    width: 100% !important;
  }
  // .ant-drawer-content-wrapper {
  //   width: 100% !important;
  // }
  // .grid_card-header-wrapper__1gSH2 {
  //   flex-direction: column;
  // }
  // .card .card-title {
  //   // font-size: 12px;
  //   margin-bottom: 10px;
  // }

  // .card-header-actions span {
  //   // font-size: 12px;
  // }

  // .grid_debounce-input__2rFkA {
  //   width: 90%;
  //   margin-bottom: 10px;
  // }
}

/* 
  custom checkbox
*/
.admodes .ant-checkbox-group {
  background: #ebedef;
}
.admodes .ant-checkbox-wrapper.ant-checkbox-group-item{
  margin-right: 0;
  border-right: 1px solid #c8bcbc;
  border-left: 1px solid #c8bcbc;
  padding: 5px;
}
.admodes .ant-checkbox-wrapper-checked {
  background: #39f;
}